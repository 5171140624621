import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { connect } from 'react-redux';

import { saveQuote } from '../../actions';

import styles from './ResultsButtons.module.scss';

class ResultsButtons extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      showInput: false,
      email: '',
      name: '',
    };
  }

  updateEmail(event) {
    this.setState({email: event.target.value});
  }

  updateName(event) {
    this.setState({name: event.target.value});
  }
  
  goToCheckout() {
    const { programType, endDate } = this.props;
    let link = '';
    if(programType === 'COACHING') {
      link = 'https://testive.chargebee.com/hosted_pages/plans/coaching-subscription?subscription[cf_end_date]=';
    }
    if(programType === 'COACHINGPLUS') {
      link = 'https://testive.chargebee.com/hosted_pages/plans/coachingplus-subscription?subscription[cf_end_date]=';
    }
    if(programType === 'BOOTCAMP') {
      link = 'https://testive.chargebee.com/hosted_pages/plans/bootcamp-2017?subscription[cf_end_date]=';
    }
    let date = format(endDate, 'YYYY-MM-DD');
    window.location.href = link.concat(date);
  }

  showSendQuoteForm() {
    this.setState({showInput: !this.state.showInput});
  }

  sendQuote(event) {
    const { saveQuote, programType, endDate, startDate, meetingsPerWeek, registeredTestDate, estimatedCost, subjectData } = this.props;
    event.preventDefault();
    let link = '';
    if(programType === 'COACHING') {
      link = 'https://testive.chargebee.com/hosted_pages/plans/coaching-subscription?subscription[cf_end_date]=';
    }
    if(programType === 'COACHINGPLUS') {
      link = 'https://testive.chargebee.com/hosted_pages/plans/coachingplus-subscription?subscription[cf_end_date]=';
    }
    if(programType === 'BOOTCAMP') {
      link = 'https://testive.chargebee.com/hosted_pages/plans/bootcamp-2017?subscription[cf_end_date]=';
    }
    let date = format(endDate, 'YYYY-MM-DD');
    link = link.concat(date);
    saveQuote({
      meetingsPerWeek: meetingsPerWeek,
      endDate: endDate,
      startDate: startDate,
      testDate: registeredTestDate,
      cost: estimatedCost,
      checkoutLink: link,
      subjectData: subjectData,
      email: this.state.email,
      name: this.state.name
    });
  }
  
  componentDidUpdate(prevProps) {
    if(!prevProps.saveQuoteSuccess && this.props.saveQuoteSuccess) {
      this.setState({
        showInput: false
      });
    }
  }

  renderSendQuoteForm() {
    return (  
      <React.Fragment>
        <form className={styles.form} onSubmit={this.sendQuote.bind(this)}>
          <div>
            <label htmlFor="email">Email:</label>
            <input name="email" type="email" required
              value={this.state.email} 
              onChange={this.updateEmail.bind(this)}/>
          </div>
          <div>
            <label htmlFor="name">Name:</label>
            <input name='name' type='text' required
              value={this.state.name} 
              onChange={this.updateName.bind(this)}/>
          </div>
          <input type="submit" value="Send" />
        </form>
      </React.Fragment>
    );
  }

  render() {
    const { saveQuoteSuccess, saveQuoteFailure } = this.props;
    return (
      <div className={styles.buttonContainer}>
        <div className={styles.buttons}>
          <button onClick={this.goToCheckout.bind(this)} className={styles.checkout}>
            Purchase Plan
          </button>
          <button onClick={this.showSendQuoteForm.bind(this)} className={styles.save}>
            Save Quote
          </button>
          
        </div>
        { 
          this.state.showInput ? 
          this.renderSendQuoteForm(): null }
        { 
          saveQuoteSuccess ?
            <div className={styles.success}>Your Quote has been emailed to you!</div>
            : null
        }
        {
          saveQuoteFailure ?
            <div className={styles.failure}>{saveQuoteFailure}</div>
            : null
        }
      </div>
    );
  }
}

ResultsButtons.propTypes = {
  programType: PropTypes.string,
  meetingsPerWeek: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  registeredTestDate: PropTypes.instanceOf(Date),
  estimatedCost: PropTypes.number,
  saveQuote: PropTypes.func,
  subjectData: PropTypes.object,
  saveQuoteSuccess: PropTypes.bool,
  saveQuoteFailure: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    saveQuote: (data) => dispatch(saveQuote(data))
  };
}

function mapStateToProps(state) {
  return {
    programType: state.programType,
    meetingsPerWeek: state.meetingsPerWeek,
    startDate: state.programStartDate,
    endDate: state.programEndDate,
    estimatedCost: state.estimatedCost,
    subjectData: state.subjectData,
    registeredTestDate: state.registeredTestDate,
    saveQuoteSuccess: state.saveQuoteSuccess,
    saveQuoteFailure: state.saveQuoteError,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsButtons);