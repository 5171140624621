import { 
  SET_MEETINGS_PER_WEEK,
  SET_START_DATE,
  SET_END_DATE,
  SET_TEST_DATE,
  SET_TEST_TYPE,
  SET_USER_SUBJECT,
  COMPLETE_QUESTION,
  SAVE_QUOTE_FAILURE,
  SAVE_QUOTE_SUCCESS,
  IMPORT_QUERYSTRING
} from '../constants/actions';

import { addWeeks, differenceInDays } from 'date-fns';

const initialState = {
  lastQuestionCompleted: 0,
  knownTestDate: undefined,
  testType: undefined,
  registeredTestDate: undefined,
  programStartDate: undefined,
  programEndDate: undefined,
  meetingsPerWeek: undefined,
  estimatedCost: undefined,
  programType: undefined,
  monthlyRate: undefined,
  saveQuoteSuccess: false,
  saveQuoteError: undefined,
  subjectData: {'reading': -1, 'writing': -1, 'math': -1, 'science': -1}
};

function getQueryState(string) {
  let queryState = {};

  if(string.testDate) {
    queryState.registeredTestDate = new Date(string.testDate);
    queryState.knownTestDate = true;
    queryState.programEndDate = new Date(string.testDate);
  }
  if(string.testType) {
    queryState.testType = string.testType;
  }

  if(string.endDate) {
    queryState.programEndDate = new Date(string.endDate);
    queryState.knownTestDate = false;
  }

  if(string.startDate) {
    queryState.programStartDate = new Date(string.startDate);
  }
  else {
    queryState.programStartDate = new Date();
  }

  if(string.meetingsPerWeek) {
    queryState.meetingsPerWeek = parseInt(string.meetingsPerWeek);
  }
  else {
    queryState.meetingsPerWeek = 1;
  }

  queryState = {
    ...queryState,
    ...calcProgramCosts(queryState.programStartDate, queryState.programEndDate, queryState.meetingsPerWeek)
  };
  return queryState;
}

function calcProgramCosts(startDate, endDate, meetingsPerWeek) {

  if (startDate && endDate) {
    const weeks = Math.round(differenceInDays(endDate, startDate)/7, 0);
    if (weeks < 8) {
      return  {
        programType: 'BOOTCAMP',
        monthlyRate: 799,
        estimatedCost: 799,
        meetingsPerWeek: 2
      };
    }
    else if (meetingsPerWeek) {
      const perMonth = (meetingsPerWeek===1) ? 399 : 599;
      return {
        programType: (meetingsPerWeek===1) ? 'COACHING' : 'COACHINGPLUS',
        monthlyRate: perMonth,
        estimatedCost: Math.round(weeks * (perMonth/4), 0),
        meetingsPerWeek: meetingsPerWeek
      };
    }
  }
  return  {
    programType: 'COACHING',
    monthlyRate: undefined,
    estimatedCost: undefined,
    meetingsPerWeek: meetingsPerWeek
  };
}

const appReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case IMPORT_QUERYSTRING:
      var queryState = getQueryState(action.string);
      return Object.assign({}, state, {
        ...queryState
      });
    
    case COMPLETE_QUESTION: 
      return Object.assign({}, state, {
        lastQuestionCompleted: action.questionNumber,
      });

    case SET_START_DATE: 
      var program = calcProgramCosts(action.startDate, state.programEndDate, state.meetingsPerWeek);
      return Object.assign({}, state, {
        programStartDate: action.startDate,
        programType: program.programType,
        monthlyRate: program.monthlyRate,
        estimatedCost: program.estimatedCost,
        meetingsPerWeek: program.meetingsPerWeek
      });

    case SET_TEST_DATE: 
      var endDate = (action.testDate) 
        ? action.testDate
        : addWeeks(state.programStartDate, 12);

      program = calcProgramCosts(state.programStartDate, endDate, state.meetingsPerWeek);
      return Object.assign({}, state, {
        testType: action.testType,
        knownTestDate: action.testDate ? true : false,
        registeredTestDate: action.testDate,
        programEndDate: endDate,
        programType: program.programType,
        monthlyRate: program.monthlyRate,
        estimatedCost: program.estimatedCost,
        meetingsPerWeek: program.meetingsPerWeek
      });

    case SET_END_DATE: 
      program = calcProgramCosts(state.programStartDate, action.endDate, state.meetingsPerWeek);
      return Object.assign({}, state, {
        programEndDate : action.endDate,
        programType: program.programType,
        monthlyRate: program.monthlyRate,
        estimatedCost: program.estimatedCost,
        // meetingsPerWeek: program.meetingsPerWeek
      });

    case SET_TEST_TYPE: 
      return Object.assign({}, state, {
        testType : action.testType
      });

    case SET_MEETINGS_PER_WEEK:
      program = calcProgramCosts(state.programStartDate, state.programEndDate, action.meetingsPerWeek);
      return Object.assign({}, state, {
        meetingsPerWeek: action.meetingsPerWeek,
        programType: program.programType,
        monthlyRate: program.monthlyRate,
        estimatedCost: program.estimatedCost
      });
     
    case SET_USER_SUBJECT:
      var s = {};
      s[action.subject.toLowerCase()] = action.value;
      return Object.assign({}, state, {
        subjectData: {
          ...state.subjectData,
          ...s
        }
      });

    case SAVE_QUOTE_FAILURE:
      return Object.assign({}, state, {
        saveQuoteError: action.error
      });

    case SAVE_QUOTE_SUCCESS:
      return Object.assign({}, state, {
        saveQuoteSuccess: true
      });

    default:
      return state;
  }

};

export default appReducer;
