import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from'./ToggleButton.module.scss';

class ToggleButton extends React.Component {
  handleClick(e) {
    e.preventDefault();

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { children, selected } = this.props;
    const buttonStyle = (selected) 
      ? cx(styles.toggleButton, styles.selected) 
      : styles.toggleButton;

    return (
      <button
        className={buttonStyle}
        onClick={(e) => this.handleClick(e)}>
        {children}
      </button>
    );
  }
}

ToggleButton.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

ToggleButton.defaultProps = {
  selected: false
};

export default ToggleButton;
