import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';

import QuestionContainer from '../QuestionContainer';
import EditButton from '../EditButton';
import TestiveDate from '../TestiveDate';
import { setEndDate } from '../../actions';
import Images from '../../assets/Images';
import styles from'./QuestionEndDate.module.scss';

class QuestionEndDate extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      editMode: false,
    };
  }

  handleSelection = (date) => {
    this.props.setEndDate(date);
    this.setState({editMode: false});
  }

  showEditMode = () => {
    this.setState({editMode: true});
  }

  render() {
    const { questionNumber, programEndDate, programStartDate, registeredTestDate } = this.props;
    const { editMode } = this.state;

    console.log('programStartDate ', programStartDate)
    if (editMode)
      return (
        <div className={styles.questionEndDate}>
          <QuestionContainer
            questionNumber={questionNumber}
            questionText={'Program Length'}>
            <Calendar 
              onChange={this.handleSelection} 
              value={programEndDate}
              minDate={programStartDate}
              minDetail='month'
              calendarType="US"/>
          </QuestionContainer>
        </div>
      );
    else {
      const infoText = (registeredTestDate===programEndDate) 
        ? 'Your current program end date is set to your test date:'
        : 'We suggest a minimum of 12 weeks or prep'
      return (
        <QuestionContainer
          questionNumber={questionNumber}
          questionText={'Program Length'}
          questionSubText={infoText}>
          <div className={styles.editQuestion}>
          <img src={Images.calendar} width={30} alt='calendar'></img>
              <div className={styles.userResponse}>
              <TestiveDate datetime={programEndDate} stringOptions='MMMM D, YYYY'/>
            </div>
            <EditButton onClick={this.showEditMode}>change date</EditButton>
          </div>
        </QuestionContainer>
      );
    }
  }
}

QuestionEndDate.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  programEndDate: PropTypes.instanceOf(Date),
  programStartDate: PropTypes.instanceOf(Date),
  registeredTestDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    programEndDate: state.programEndDate,
    programStartDate: state.programStartDate,
    registeredTestDate: state.registeredTestDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setEndDate: (endDate) => dispatch(setEndDate(endDate)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionEndDate);
