import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';

import QuestionContainer from '../QuestionContainer';
import EditButton from '../EditButton';
import TestiveDate from '../TestiveDate';
import { setStartDate } from '../../actions';
import Images from '../../assets/Images';
import styles from'./QuestionStartDate.module.scss';

class QuestionStartDate extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      editMode: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.editMode && !prevProps.startDate && this.props.startDate) {
      this.setState({editMode: false});
    }
  }

  handleSelection = (date) => {
    this.props.setStartDate(date);
    this.setState({editMode: false});
  }

  showEditMode = () => {
    this.setState({editMode: true});
  }

  render() {
    const { questionNumber, startDate } = this.props;
    const { editMode } = this.state;
    const title = 'When can your child get started?';
    const subTitle = 'To get started your child will need to complete our baseline exam';

    if (editMode)
      return (
        <div className={styles.questionStartDate}>
          <QuestionContainer
            questionNumber={questionNumber}
            questionText={title}
            questionSubText={subTitle}>
            <Calendar 
              onChange={this.handleSelection} 
              minDate={new Date()}
              minDetail='month'
              value={startDate ? startDate : new Date()}
              calendarType="US"/>
          </QuestionContainer>
        </div>
      );
    else {
      return (
        <QuestionContainer
          questionNumber={questionNumber}
          questionText={title}
          questionSubText={subTitle}>
          <div className={styles.editQuestion}>
            <img src={Images.calendar} width={30} alt='calendar'></img>
            <div className={styles.userResponse}>
              <TestiveDate datetime={startDate} stringOptions='MMMM D, YYYY'/>
            </div>
            <EditButton onClick={this.showEditMode}>change date</EditButton>
          </div>
        </QuestionContainer>
      );
    }
  }
}

QuestionStartDate.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    startDate: state.programStartDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setStartDate: (testDate) => dispatch(setStartDate(testDate)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionStartDate);
