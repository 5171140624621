import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { timeZoneMapIANA } from '../../constants/TimeZones';

function TestiveDate({ removeCommas, formatOptions, datetime, timeZone, stringOptions = 'ddd M/D h:mmA'}) {
  let displayTime = datetime;

  if (timeZone) {
    let options = formatOptions ? formatOptions : {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      weekday: 'long',
      timeZone: timeZoneMapIANA[timeZone] ? timeZoneMapIANA[timeZone] : 'UTC',
      timeZoneName: 'short'
    };

    let date = format(displayTime, 'x');
    date = new Date(parseInt(date));

    displayTime = date.toLocaleString('en-us', options);

    if (removeCommas) {
      displayTime = displayTime.replace(/,/g, '');
    }
  }
  else {

    displayTime = format(displayTime, stringOptions);
    if(displayTime.indexOf('Z') !== -1) {
      displayTime = displayTime.slice(0,displayTime.length-1);
    }
    
  }

  return (displayTime);

}

TestiveDate.propTypes = {
  removeCommas: PropTypes.bool,
  formatOptions: PropTypes.object,
  datetime: PropTypes.instanceOf(Date),
  timeZone: PropTypes.string,
  stringOptions: PropTypes.string
};

export default TestiveDate;
