export default [
  {
    displayString: 'October 23rd ACT',
    testType: 'SAT',
    testDate: '10/23/2021'
  },
  {
    displayString: 'November 6th SAT',
    testType: 'ACT',
    testDate: '11/6/2021'
  },
  {
    displayString: 'December 4th SAT',
    testType: 'SAT',
    testDate: '12/4/2021'
  },
  {
    displayString: 'December 11th ACT',
    testType: 'SAT',
    testDate: '12/11/2021'
  },
  {
    displayString: 'February 12th ACT',
    testType: 'ACT',
    testDate: '2/12/2022'
  },
  {
    displayString: 'March 12th SAT',
    testType: 'ACT',
    testDate: '3/12/2022'
  },
  {
    displayString: 'April 2nd ACT',
    testType: 'ACT',
    testDate: '4/2/2022'
  },
  {
    displayString: 'May 7th SAT',
    testType: 'ACT',
    testDate: '5/7/2022'
  },
  {
    displayString: 'June 4th SAT',
    testType: 'ACT',
    testDate: '6/4/2022'
  },
  {
    displayString: 'June 11th ACT',
    testType: 'ACT',
    testDate: '6/11/2022'
  },
  {
    displayString: 'July 16th ACT',
    testType: 'ACT',
    testDate: '7/16/2022'
  },
]

// act
// June 13, 2020
// July 18, 2020

// sat
// August 22, 2020
// October 3, 2020
// November 7, 2020
// December 5, 2020
// March 13, 2021
// May 1, 2021
// June 5, 2021
// August 21, 2021
// October 2, 2021
// November 6, 2021
// December 4, 2021
// March 12, 2022
// May 7, 2022
// June 4, 2022
// August 20, 2022
// October 1, 2022
// November 5, 2022
// December 3, 2022
// March 11, 2023
// May 6, 2023
// June 3, 2023
// August 19, 2023
// October 7, 2023
// November 4, 2023
// December 2, 2023
