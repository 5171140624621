import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToggleButton from '../ToggleButton';
import QuestionContainer from '../QuestionContainer';

import { setMeetingsPerWeek } from '../../actions';

import styles from'./QuestionCoachingSessions.module.scss';

class QuestionCoachingSessions extends React.Component {

  render() {
    const { meetingsPerWeek, 
      questionNumber, 
      setMeetingsPerWeek } = this.props;
    return (
      <QuestionContainer 
        questionNumber={questionNumber}
        questionText='How many times per week do you want your child
        to meet with a coach?'
        questionSubText='Most students meet once per week. If you want extra
        accountability, choose twice per week.'>

        <div className={styles.buttons}>
          <ToggleButton 
            onClick={(e) => setMeetingsPerWeek(1)}
            selected={meetingsPerWeek===1}>
            Once a Week
          </ToggleButton>
          <ToggleButton 
            onClick={(e) => setMeetingsPerWeek(2)}
            selected={meetingsPerWeek===2}>
            Twice a Week
          </ToggleButton>
        </div>
        
      </QuestionContainer>
    );
  }
}

QuestionCoachingSessions.propTypes = {
  meetingsPerWeek: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    meetingsPerWeek: state.meetingsPerWeek
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMeetingsPerWeek: (meetingsPerWeek) => dispatch(setMeetingsPerWeek(meetingsPerWeek)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCoachingSessions);
