import { 
  COMPLETE_QUESTION,
  SET_END_DATE,
  SET_TEST_DATE,
  SET_MEETINGS_PER_WEEK,
  SET_START_DATE,
  SET_TEST_TYPE,
  SET_USER_SUBJECT,
  SAVE_QUOTE_REQUEST,
  SAVE_QUOTE_FAILURE,
  SAVE_QUOTE_SUCCESS,
  IMPORT_QUERYSTRING
} from '../constants/actions';

import { EventTypes } from 'redux-segment';
import { format } from 'date-fns';

export function importQuerystring(string) {
  return {
    type: IMPORT_QUERYSTRING,
    string: string
  };
}

export function completeQuestion(questionNumber) {
  return {
    type: COMPLETE_QUESTION,
    questionNumber: questionNumber
  };
}

export function setStartDate(startDate) {
  return {
    type: SET_START_DATE,
    startDate: startDate,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: '[BAP] Set Start Date'
        }
      }
    }
  };
}

export function setEndDate(endDate) {
  return {
    type: SET_END_DATE,
    endDate: endDate ? new Date(endDate) : undefined,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: '[BAP] Set End Date'
        }
      }
    }
  };
}

export function setTestType(testType) {
  return {
    type: SET_TEST_TYPE,
    testType: testType,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: '[BAP] Set Test Type'
        }
      }
    }
  };
}

export function setTestDate(testType, testDate) {
  return {
    type: SET_TEST_DATE,
    testType: testType,
    testDate: testDate ? new Date(testDate) : undefined,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: '[BAP] Set Test Date'
        }
      }
    }
  };
}

export function setUserSubject(subject, value) {
  return {
    type: SET_USER_SUBJECT,
    subject: subject,
    value: value,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: '[BAP] Set User Subject'
        }
      }
    }
  };
}

export function setMeetingsPerWeek(meetingsPerWeek) {
  return {
    type: SET_MEETINGS_PER_WEEK,
    meetingsPerWeek: meetingsPerWeek,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: '[BAP] Set Meetings Per Week'
        }
      }
    }
  };
}

function saveQuoteRequest(email) {
  return {
    type: SAVE_QUOTE_REQUEST,
    meta: {
      analytics: {
        eventType: EventTypes.alias,
        eventPayload: {
          userId: email
        }
      }
    }
  };
}

function saveQuoteFailure(error) {
  return { 
    type: SAVE_QUOTE_FAILURE,
    error: error
  };
}

function saveQuoteSuccess() {
  return {
    type: SAVE_QUOTE_SUCCESS,
  };
}

export function saveQuote(data) {
  return function (dispatch) {
    dispatch(saveQuoteRequest(data.email));

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];
    let start_date = format(data.startDate, 'MM-DD-YYYY');
    let end_date = format(data.endDate, 'MM-DD-YYYY');
    formData.push(encodeURIComponent('email') + '=' + encodeURIComponent(data.email));
    formData.push(encodeURIComponent('start_date') + '=' + encodeURIComponent(start_date));
    formData.push(encodeURIComponent('end_date') + '=' + encodeURIComponent(end_date));
    formData.push(encodeURIComponent('meetings_per_week') + '=' + encodeURIComponent(data.meetingsPerWeek));
    formData.push(encodeURIComponent('name') + '=' + encodeURIComponent(data.name));
    formData.push(encodeURIComponent('cost') + '=' + encodeURIComponent(data.cost));
    formData.push(encodeURIComponent('checkout_link') + '=' + encodeURIComponent(data.checkoutLink));
    if(data.testDate) {
      let test_date = format(data.testDate, 'MM-DD-YYYY');
      formData.push(encodeURIComponent('test_date') + '=' + encodeURIComponent(test_date));
    }
    for(let s in data.subjectData) {
      let v;
      switch(data.subjectData[s]) {
        case 0:
          v = 'Stronger';
          break;
        case 1:
          v = 'Weaker';
          break;
        default:
          v = 'Not Sure';
          break;
      }
      formData.push(encodeURIComponent(s) + '=' + encodeURIComponent(v));
    }
  
    return fetch('https://app.testive.com/api/a/checkout_save_quote/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(saveQuoteFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(saveQuoteSuccess());
          } else if(json.status === 'error'){
            dispatch(saveQuoteFailure(json.msg));
          }
        }
      );
  }; 
}
