import React from 'react';
import './App.css';
import SimpleHeader from './components/SimpleHeader';
import PlanBuilder from './components/PlanBuilder';

function App() {
  return (
    <div className="App">
      <SimpleHeader />
      <PlanBuilder/>
    </div>
  );
}

export default App;
