import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import PlanResults from '../PlanResults';
import PlanQuestionSet from '../PlanQuestionSet';
import { importQuerystring } from '../../actions';

import styles from'./PlanBuilder.module.scss';

class PlanBuilder extends React.Component {

  componentDidMount() {
    let query = queryString.parse(window.location.search);
    if(Object.entries(query).length > 0) {
      this.props.importQuerystring(query);
    }
  }
  
  render() {
    return (
      <div className={styles.planBuilder}>
        <PlanQuestionSet></PlanQuestionSet>
        <div className={styles.results}> 
          <PlanResults></PlanResults>
        </div>
      </div>
    );
  }
}

PlanBuilder.propTypes = {
  importQuerystring: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    importQuerystring: (string) => dispatch(importQuerystring(string))
  };
}

export default connect(null, mapDispatchToProps)(PlanBuilder);
