import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TestiveDate from '../TestiveDate';
import Images from '../../assets/Images';
import styles from'./ResultsDates.module.scss';

class ResultsDates extends React.Component {
  
  renderDate(date) {

    if (!date) {
      return (
        <React.Fragment>
          <div className={styles.dateDay}>?</div>
        </React.Fragment>);

    }
    const month = <TestiveDate datetime={date} stringOptions='MMMM'/>
    const day = <TestiveDate datetime={date} stringOptions='D'/>
    const year = <TestiveDate datetime={date} stringOptions='YYYY'/>

    return (
      <React.Fragment>
        <div className={styles.dateMonth}>{month}</div>
        <div className={styles.dateDay}>{day}</div>
        <div className={styles.dateYear}>{year}</div>
      </React.Fragment>);
  }

  render() {
    const { startDate, endDate } = this.props;
    
    const startDateBlock = this.renderDate(startDate);
    const endDateBlock = this.renderDate(endDate);
  
    return (
      <div className={styles.resultsDates}>
        <div className={styles.dateBlock}>
          <div className={styles.date}>{startDateBlock}</div>
          <div className={styles.dateLabel}>Start Date</div>
        </div>
        <img src={Images.rightArrow} alt='thru' width={40}></img>
        <div className={styles.dateBlock}>
          <div className={styles.date}>{endDateBlock}</div>
          <div className={styles.dateLabel}>End Date</div>
        </div>
      </div>
    );
  }
}

ResultsDates.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date)
};

function mapStateToProps(state) {
  return {
    startDate: state.programStartDate,
    endDate: state.programEndDate,
  };
}

export default connect(mapStateToProps)(ResultsDates);
