import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from'./EditButton.module.scss';

class EditButton extends React.Component {
  handleClick(e) {
    e.preventDefault();

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { children, disabled } = this.props;
    const buttonStyle = (disabled) 
      ? cx(styles.button, styles.disabled) 
      : styles.button

    return (
      <button
        className={buttonStyle}
        disabled={this.props.disabled || false}
        onClick={(e) => this.handleClick(e)}>
        {children}
      </button>
    );
  }
}

EditButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

EditButton.defaultProps = {
  disabled: false
};

export default EditButton;
