import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestDatePicker.module.scss';
import testDates from '../../constants/testDates';
import { differenceInCalendarMonths } from 'date-fns';


class TestDatePicker extends React.Component {
  
  handleSelection = (e) => {
    const selectedTest = testDates[e.target.value];
    this.props.testDateSelected(selectedTest.testType, selectedTest.testDate);
  }

  testDateOptions() {
    const today = new Date();
  
    const testDateOptions = testDates.map((q, i) => {
      var testDate = new Date(q.testDate);
      if ((testDate > today) && (differenceInCalendarMonths(testDate, today) <= 6)) 
      {
        return (
          <option 
            value={i}
            key={i}>
            {q.displayString}
          </option>);
      }})
    return testDateOptions;
  }

  render() {
    const { selectedValue } = this.props;
    return (
      <div className={styles.testDatePicker}>
        <select onChange={(e) => this.handleSelection(e)}>
          <option hidden defaultValue>select your test date</option>
          {this.testDateOptions()}
        </select>
      </div>
    );
  }
}

TestDatePicker.propTypes = {
  testDateSelected: PropTypes.func.isRequired,
  selectedValue: PropTypes.string
};

export default TestDatePicker;
