import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { differenceInWeeks } from 'date-fns';
import cx from 'classnames';

import ResultsDates from './../ResultsDates';
import ResultsMeetings from '../ResultsMeetings';
import ResultsPrice from '../ResultsPrice';
import ResultsButtons from '../ResultsButtons';

import styles from'./PlanResults.module.scss';

class PlanResults extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fixed: false
    };
  }
  
  scheduleCall() {
    window.location.href = 'https://www.testive.com/schedule-a-call/';
  }

  componentDidMount() {
    window.addEventListener('scroll', (function() {
      if(window.scrollY >= 80 && !this.state.fixed) {
        this.setState({fixed: true});
      }
      else if(window.scrollY < 80 && this.state.fixed) {
        this.setState({fixed: false});
      }
    }).bind(this));
  }

  render() {
    const { startDate, endDate, meetingsPerWeek, programType, subjectData, testType } = this.props;
    const resultsStyle  = this.state.fixed ? cx(styles.planResults, styles.fixed) : styles.planResults;
    if(startDate && endDate) {
      if(differenceInWeeks(endDate, startDate) < 4) {
        return (
          <div className={resultsStyle}>
            <div className={styles.title}>Your Testive Program</div>
            <ResultsDates startDate={startDate} endDate={endDate} />

            <div className={styles.shortProgramContainer}>
              <div className={styles.shortProgram}>
                Your program length is shorter than we recommend, please schedule a call to discuss
                a custom plan with one of our Student Success Advisors
              </div>
              <button onClick={this.scheduleCall.bind(this)} className={styles.callButton}>
                Schedule A Call
              </button>
            </div>
          </div>
        );
      }
    }
    return (
      <div className={resultsStyle}>
        <div className={styles.title}>Your Testive Program</div>
        {
          startDate || meetingsPerWeek ?
            null
            : <div className={styles.empty}>Fill out your info to build your custom plan</div>
        }
        { startDate
          ? <ResultsDates
            startDate={startDate}
            endDate={endDate} /> 
          : null }
        { meetingsPerWeek
          ? <ResultsMeetings
            meetingsPerWeek={meetingsPerWeek}
            programType={programType}
            testType={testType} />
          : null }
        { meetingsPerWeek
          ? <ResultsPrice />
          : null }
        { Object.values(subjectData).every((v) => v >= 0)
          ? <ResultsButtons />
          : null }
      </div>
    );
  }
}

PlanResults.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  meetingsPerWeek: PropTypes.number,
  programType: PropTypes.string,
  subjectData: PropTypes.object,
  testType: PropTypes.string
};

function mapStateToProps(state) {
  return {
    startDate: state.programStartDate,
    endDate: state.programEndDate,
    meetingsPerWeek: state.meetingsPerWeek,
    programType: state.programType,
    subjectData: state.subjectData,
    testType: state.testType
  };
}

export default connect(mapStateToProps)(PlanResults);
