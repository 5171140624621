import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QuestionContainer from '../QuestionContainer';
import TestSubjectRating from '../TestSubjectRating';
import { setUserSubject } from '../../actions';

import styles from'./QuestionSubjects.module.scss';

class QuestionSubjects extends React.Component {

 render() {
    const { questionNumber, subjectData, setUserSubject } = this.props;
    
    return (
      <QuestionContainer
        questionNumber={questionNumber}
        questionText={'Where do you see your child\'s strengths and weaknesses?'}>
        <div className={styles.subjectList}>
          <TestSubjectRating 
            title={'Reading'} 
            ratingSelected={setUserSubject} 
            selectedValue={subjectData['reading']}/>
          <TestSubjectRating 
            title={'Writing'} 
            ratingSelected={setUserSubject} 
            selectedValue={subjectData['writing']}/>
          <TestSubjectRating 
            title={'Math'} 
            ratingSelected={setUserSubject}
            selectedValue={subjectData['math']}/>
          <TestSubjectRating 
            title={'Science'} 
            ratingSelected={setUserSubject} 
            selectedValue={subjectData['science']}/>
        </div>
      </QuestionContainer>
    );
  }
}

QuestionSubjects.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  subjectData: PropTypes.object,
  setUserSubject: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    subjectData: state.subjectData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserSubject: (subject, value) => dispatch(setUserSubject(subject, value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSubjects);
