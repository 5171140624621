import React from 'react';
import PropTypes from 'prop-types';

import styles from'./QuestionContainer.module.scss';

class QuestionContainer extends React.Component {

  render() {
    const { questionNumber, questionText, questionSubText, children } = this.props;
    
    return (
      <div className={styles.questionContainer}>
        <div className={styles.questionHeader}>
          <div className={styles.questionNumber}>{questionNumber}.</div>
          <div className={styles.questionText}>{questionText}</div>
        </div>
        <div className={styles.questionBody}>
          <div className={styles.questionSubText}>{questionSubText}</div>
          {children}
        </div>
      </div>
    );
    
  }
}

QuestionContainer.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  qustionSubText: PropTypes.string,
  children: PropTypes.node,
};


export default QuestionContainer;
