import React from 'react';
import styles from './SimpleHeader.module.scss';

class SimpleHeader extends React.Component {

  render() {
    return (
      <div className={styles.simpleHeader}>
        <a href="https://www.testive.com"><img src={require('../../assets/testivelogo1.svg')} alt="Testive Logo" className={styles.logo} /></a>
        <a href="https://www.testive.com/schedule-a-call/">Questions? Schedule a call with a Student Success Advisor</a>  
      </div>
    );
  }
}

export default SimpleHeader;