// for when you want to use Date.prototype.toLocaleString()
export const timeZoneMapIANA = {
  'US/Eastern': 'America/New_York',
  'US/Central': 'America/Chicago',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
  'US/Alaska': 'America/Anchorage',
  'US/Hawaii': 'Pacific/Honolulu'
};

// supported time zones in testive_v3, see TIME_ZONE_MAP in date_utils.py
// only supporting US time zones here for now, plus UTC
export const timeZoneMapAbbrev = {
  'EDT': 'US/Eastern',
  'EST': 'US/Eastern',
  'CDT': 'US/Central',
  'CST': 'US/Central',
  'MDT': 'US/Mountain',
  'MST': 'US/Mountain',
  'PDT': 'US/Pacific',
  'PST': 'US/Pacific',
  'AKDT': 'US/Alaska',
  'AKST': 'US/Alaska',
  'HST': 'US/Hawaii',
  // 'ADT': 'America/Buenos_Aires',
  // 'AST': 'America/Santiago',
  // 'EEST': 'Europe/Moscow',
  // 'CT': 'Asia/Hong_Kong',
  // 'JST': 'Asia/Tokyo',
  // 'AEST': 'Pacific/Guam',
  // 'AEDT': 'Etc/GMT+11',
  // 'LINT': 'Pacific/Auckland'
};
