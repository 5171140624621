import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QuestionEndDate from '../QuestionEndDate';
import QuestionStartDate from './../QuestionStartDate';
import QuestionSubjects from './../QuestionSubjects';
import QuestionTestDate from '../QuestionTestDate';
import QuestionTestType from '../QuestionTestType';
import QuestionCoachingSessions from './../QuestionCoachingSessions';

import styles from'./PlanQuestionSet.module.scss';

class PlanQuestionSet extends React.Component {
  
  buildQuestionSet() {
    const { 
      programStartDate, 
      knownTestDate,
      testType,
      programEndDate, 
      meetingsPerWeek,
      isBootcamp } = this.props;

    var questions = [];
    questions.push(<QuestionStartDate questionNumber={1} key={1}/>);
    if (programStartDate)
    {
      questions.push(<QuestionTestDate questionNumber={2} key={2}/>);

      // figure out what question 3 is
      // if user does not know the test date ask about test type
      if (knownTestDate===false) {
        questions.push(<QuestionTestType questionNumber={3} key={3}/>);
        if (testType) {
          questions.push(<QuestionEndDate questionNumber={4} key={4}/>);
          if (programEndDate ) {
            questions.push(<QuestionCoachingSessions questionNumber={5} key={5}/>);
            if (meetingsPerWeek) {
              questions.push(<QuestionSubjects questionNumber={6} key={6}/>);
            }
          }
        }
      }

      // if the user does know their test date move on
      else if (knownTestDate===true) {
        questions.push(<QuestionEndDate questionNumber={3} key={3}/>);

        if (isBootcamp) {
          questions.push(<QuestionSubjects questionNumber={4} key={4}/>);
        }
        else {
          questions.push(<QuestionCoachingSessions questionNumber={4} key={4}/>);
          if (meetingsPerWeek) {
            questions.push(<QuestionSubjects questionNumber={5} key={5}/>);
          }
        } 

      }
    }

    return questions;
  }

  render() {
    const questionList =this.buildQuestionSet();
    return (
      <div className={styles.planQuestionSet}>
        <div className={styles.title}>Build your personalized prep plan</div>
        <div>
          {questionList}
        </div>  
      </div>
      
    );
  }
}

PlanQuestionSet.propTypes = {
  knownTestDate: PropTypes.bool,
  programStartDate: PropTypes.instanceOf(Date),
  programEndDate: PropTypes.instanceOf(Date),
  registeredTestDate: PropTypes.instanceOf(Date),
  testType: PropTypes.string,
  meetingsPerWeek: PropTypes.number,
  isBootcamp: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    knownTestDate: state.knownTestDate,
    programStartDate: state.programStartDate,
    programEndDate: state.programEndDate,
    registeredTestDate: state.registeredTestDate,
    testType: state.testType,
    meetingsPerWeek: state.meetingsPerWeek,
    isBootcamp: state.programType === 'BOOTCAMP'
  };
}

export default connect(mapStateToProps)(PlanQuestionSet);
