export const COMPLETE_QUESTION = 'COMPLETE_QUESTION';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_TEST_DATE = 'SET_TEST_DATE';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_MEETINGS_PER_WEEK = 'SET_MEETINGS_PER_WEEK';
export const SET_TEST_TYPE = 'SET_TEST_TYPE';
export const SET_USER_SUBJECT = 'SET_USER_SUBJECT';
export const SAVE_QUOTE_REQUEST = 'SAVE_QUOTE_REQUEST';
export const SAVE_QUOTE_FAILURE = 'SAVE_QUOTE_FAILURE';
export const SAVE_QUOTE_SUCCESS = 'SAVE_QUOTE_SUCCESS';
export const SET_ESTIMATED_COST = 'SET_ESTIMATED_COST';
export const IMPORT_QUERYSTRING = 'IMPORT_QUERYSTRING';
