import React from 'react';
import PropTypes from 'prop-types';

import styles from './ResultsMeetings.module.scss';

class ResultsMeetings extends React.Component {

  render() {
    const { meetingsPerWeek, programType, testType } = this.props;

    if (programType === 'BOOTCAMP')
      return (
        <div className={styles.resultsMeetings}>
          <div className={styles.programInfo}>{testType ? 'Testive '+testType+' Bootcamp' : 'Testive Bootcamp'}</div>
          <div className={styles.meetingInfo}><span className={styles.number}>2 </span> Meetings Per Week</div>
        </div>
      );
    else
      return (
        <div className={styles.resultsMeetings}>
          <div className={styles.programInfo}>{testType ? 'Testive '+testType+' Coaching Program' : 'Testive Coaching Program'}</div>
          <div className={styles.meetingInfo}>
            <span className={styles.number}>{meetingsPerWeek + ' '}</span>
            Coaching Session{ meetingsPerWeek > 1 ? 's' : ''} Per Week
          </div>
        </div>
      );
  }
}

ResultsMeetings.propTypes = {
  meetingsPerWeek: PropTypes.number.isRequired,
  programType: PropTypes.string.isRequired,
  testType: PropTypes.string.isRequired
};

export default ResultsMeetings;