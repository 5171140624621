import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TestDatePicker from '../TestDatePicker';
import Button from '../Button';
import EditButton from '../EditButton';
import TestiveDate from '../TestiveDate';
import QuestionContainer from '../QuestionContainer';
import { setTestDate } from '../../actions';
import Images from '../../assets/Images';
import styles from'./QuestionTestDate.module.scss';

class QuestionTestDate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editMode: !(props.registeredTestDate || props.programEndDate),
    };
  }

  handleTestDateSelection = (testType, testDate) => {
    this.props.setTestDate(testType, testDate);
    this.setState({editMode: false});
  }

  handleUnknownTestDate = () => {
    this.props.setTestDate(undefined, undefined);
    this.setState({editMode: false});
  }

  showEditMode = () => {
    this.setState({editMode: true});
  }

  renderUserResponse() {
    const { registeredTestDate, questionNumber, testType, knownTestDate } = this.props;
    const qTitle = 'Is your child targeting a specific test date?';

    if (knownTestDate)
      return (
        <QuestionContainer 
          questionNumber={questionNumber}
          questionText={qTitle} 
          questionSubText={'Your official test date:'}>
          <div className={styles.editQuestion}>
            <img src={Images.calendar} width={30} alt='calendar'></img>
            <div className={styles.userResponse}>
              <TestiveDate datetime={registeredTestDate} stringOptions='MMMM D, YYYY'/>
              <div className={styles.testType}>{testType}</div>
            </div>
            <EditButton onClick={this.showEditMode}>change date</EditButton>
          </div>
        </QuestionContainer>);
    else
      return (
        <QuestionContainer 
          questionNumber={questionNumber}
          questionText={qTitle}>
          <div className={styles.editQuestion}>
            <div className={styles.userResponse}>
              Not Sure
            </div>
            <EditButton onClick={this.showEditMode}>change answer</EditButton>
          </div>
        </QuestionContainer>);
  }

  renderEditMode() {
    const { questionNumber } = this.props;
    const qTitle = 'Is your child targeting a specific test date?';
    
    return (
      <QuestionContainer 
        questionNumber={questionNumber}
        questionText={qTitle}>
        <div className={styles.questionTestDate}>
          <TestDatePicker testDateSelected={(t,d) => this.handleTestDateSelection(t,d)}/>
          <Button onClick={this.handleUnknownTestDate}>not sure</Button>
        </div>
      </QuestionContainer>  
    );
  }

  render() {
    const { editMode } = this.state;
    if (editMode)
      return this.renderEditMode();
    else  {
      return this.renderUserResponse();
    }
  }
}

QuestionTestDate.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  knownTestDate: PropTypes.bool,
  testType: PropTypes.string,
  registeredTestDate: PropTypes.instanceOf(Date),
  setTestDate: PropTypes.func.isRequired,
  programEndDate: PropTypes.instanceOf(Date),
};

function mapStateToProps(state) {
  return {
    knownTestDate: state.knownTestDate,
    testType: state.testType,
    registeredTestDate: state.registeredTestDate,
    programEndDate: state.programEndDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTestDate: (testType, testDate) => dispatch(setTestDate(testType, testDate)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTestDate);
