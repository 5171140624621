import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ToggleButton from '../ToggleButton';
import QuestionContainer from '../QuestionContainer';
import { setTestType } from '../../actions';

import styles from'./QuestionTestType.module.scss';

class QuestionTestType extends React.Component {

  render() {
    const { questionNumber, testType, setTestType } = this.props;
  
    const qTitle = 'Which test are they preparing for?';

    return (
      <QuestionContainer 
        questionNumber={questionNumber}
        questionText={qTitle}>
        <div className={styles.buttons}>
          <ToggleButton 
            onClick={(testType) => setTestType('SAT')} 
            selected={testType==='SAT'}
            >SAT</ToggleButton>
          <ToggleButton 
            onClick={(testType) => setTestType('ACT')}
            selected={testType==='ACT'}
            >ACT</ToggleButton>
          <ToggleButton 
            onClick={(testType) => setTestType('NOTSURE')}
            selected={testType==='NOTSURE'}
            >I don't know</ToggleButton>
        </div>
      </QuestionContainer> 
    );
    
  }
}

QuestionTestType.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  testType: PropTypes.string,
  setTestType: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    testType: state.testType
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTestType: (testType) => dispatch(setTestType(testType))
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTestType);
