import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './ResultsPrice.module.scss';

class ResultsPrice extends React.Component {


  render() {
    const { estimatedCost, monthlyRate, isBootcamp }  = this.props;
 
    if (isBootcamp) {
      return (
        <div className={styles.priceContainer}>
          <div className={styles.totalCost}>
            <span className={styles.number}>${estimatedCost}</span>
          Total Cost
          </div>
          <div className={styles.monthlyCost}>
            <span className={styles.number}>${estimatedCost}</span>
          Upfront Cost
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={styles.priceContainer}>
          <div className={styles.dueToday}>
            <span className={styles.number}>${monthlyRate}</span>
          Due Today
          </div>
          <div className={styles.totalCost}>
            <span className={styles.number}>${estimatedCost}</span>
          Estimated Total
          </div>
          <div className={styles.monthlyCost}>
            <span className={styles.number}>${monthlyRate}</span>
          Per Month
          </div>
        </div>
      );
    }
  }
}

ResultsPrice.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  meetingsPerWeek: PropTypes.number,
  estimatedCost: PropTypes.number,
  monthlyRate: PropTypes.number,
  isBootcamp: PropTypes.bool,
  setEstimatedCost: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    startDate: state.programStartDate,
    endDate: state.programEndDate,
    meetingsPerWeek: state.meetingsPerWeek,
    estimatedCost: state.estimatedCost,
    monthlyRate: state.monthlyRate,
    isBootcamp: state.programType === 'BOOTCAMP',
  };
}

export default connect(mapStateToProps)(ResultsPrice);