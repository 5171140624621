import React from 'react';
import PropTypes from 'prop-types';

import ToggleButton from '../ToggleButton';

import styles from'./TestSubjectRating.module.scss';

class TestSubjectRating extends React.Component {

  render() {
    const { title, selectedValue, ratingSelected } = this.props;
    
    return (
      <div className={styles.testSubjectRating}>
        <div className={styles.title}>{title}</div>
        <div className={styles.buttons}>
          <ToggleButton 
            selected={selectedValue===0}
            onClick={(e) => ratingSelected(title, 0)}>
            stronger</ToggleButton>
          <ToggleButton 
            selected={selectedValue===1}
            onClick={(e) => ratingSelected(title, 1)}
          >weaker</ToggleButton>
          <ToggleButton 
            selected={selectedValue===2}
            onClick={(e) => ratingSelected(title, 2)}
          >not sure</ToggleButton>
        </div>
      </div>
    ); 
  }
}

TestSubjectRating.propTypes = {
  title: PropTypes.string.isRequired,
  selectedValue: PropTypes.number,
  ratingSelected: PropTypes.func.isRequired
};

export default TestSubjectRating;
